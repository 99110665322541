.marquee-container {
    width: 100%;
    height: 10em;
    margin: 2em auto;
    overflow: hidden;
    background: #ffffff;
    position: relative;
}

.marquee-slider {
    top: 1em;
    position: relative;
    box-sizing: border-box;
    animation: slider 15s linear infinite;
    list-style-type: none;
    text-align: center;
}

.marquee-slider:hover {
    animation-play-state: paused;
}

@keyframes slider {
    0%   { top:   10em }
    100% { top: -14em }
}

.blur .marquee-slider {
  	margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
}

.blur:before, .blur::before,
.blur:after,  .blur::after {
    left: 0;
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%; 
    height: 2em;
    background-image: linear-gradient(180deg, #FFF, rgba(255,255,255,0));
}

.blur:after, .blur::after {
    bottom: 0;
    transform: rotate(180deg);
}

.blur:before, .blur::before {
    top: 0;
}
