.gallery {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.gallery .pics {
    width: 120px;
    height: 120px;
    margin: 2px;
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
}

.gallery .pics:hover {
    filter: opacity(.8);
}

.gallery .pics .img {
    object-fit: contain;
    width: inherit;
    height: inherit;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    background-color: #000;
    justify-content: center;
    align-items: center;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 2;
}

.modelopen {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: 500px;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modelopen i {
    color: #fff;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: #0e0e0e;
    cursor: pointer;
}