/* .application__wrapper-header {
  background: linear-gradient(45deg, #169552, #b8e6d2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 155px;
  padding: 0;
}

a.active {
  color: #b8e6d2 !important;
} */

/*Payment design*/

.card {
    border: none;
    border-radius: 8px;
    box-shadow: 5px 6px 6px 2px #e9ecef;
  }
  .heading {
    font-size: 23px;
    font-weight: 00;
  }
  .text {
    font-size: 16px;
    font-weight: 500;
    color: #b1b6bd;
  }
  .pricing {
    border: 2px solid #000;
    background-color: #f2f5ff;
  }
  .pricing2 {
    border: 2px solid #2DDAB5;
    background-color: #f2f5ff;
  }
  .business {
    font-size: 20px;
    font-weight: 500;
  }
  .plan {
    color: #aba4a4;
  }
  .dollar {
    font-size: 16px;
    color: #6b6b6f;
  }
  .amount {
    font-size: 50px;
    font-weight: 500;
  }
  .year {
    font-size: 20px;
    color: #6b6b6f;
    margin-top: 19px;
  }
  .detail {
    font-size: 22px;
    font-weight: 500;
  }
  .cvv {
    height: 44px;
    width: 73px;
    border: 2px solid #eee;
  }
  .cvv:focus {
    box-shadow: none;
    border: 2px solid #304ffe;
  }
  .email-text {
    height: 55px;
    border: 2px solid #eee;
  }
  .email-text:focus {
    box-shadow: none;
    border: 2px solid #304ffe;
  }
  .payment-button {
    height: 70px;
    font-size: 20px;
  }
  